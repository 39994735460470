import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['text', 'showMoreButton', 'showLessButton'];

    static values = {
        offset: { type: Number, default: 2 },
        open: Boolean,
    };

    initialize() {
        this.initializedValue = false;

        if (this.countLines() <= this.offsetValue) {
            this.showMoreButtonTarget.classList.toggle('tw-hidden', true);
            this.showLessButtonTarget.classList.toggle('tw-hidden', true);

            return;
        }

        this.initializedValue = true;
    }

    openValueChanged() {
        if (!this.initializedValue) {
            return;
        }

        let currentElement = this.textTarget.querySelector('p');

        currentElement.classList.toggle(`tw-line-clamp-${this.offsetValue}`, !this.openValue);

        while (currentElement.nextElementSibling) {
            currentElement = currentElement.nextElementSibling;
            currentElement.classList.toggle('tw-hidden', !this.openValue);
        }

        this.showMoreButtonTarget.classList.toggle('tw-hidden', this.openValue);
        this.showLessButtonTarget.classList.toggle('tw-hidden', !this.openValue);
    }

    countLines() {
        const currentElement = this.textTarget.querySelector('p');
        const divHeight = currentElement.offsetHeight;
        const lineHeight = parseInt(window.getComputedStyle(currentElement).getPropertyValue('line-height'), 10);

        return divHeight / lineHeight;
    }

    show() {
        this.openValue = true;
    }

    hide() {
        this.openValue = false;
    }
}
