const weboramaComponent = {
    init() {
        if (document.getElementById('weborama_script')) {
            document.addEventListener('userSessionUpdated', () => {
                const user = JSON.parse(sessionStorage.getItem('user'));

                document.dispatchEvent(
                    new CustomEvent('triggerWeborama', {
                        detail: {
                            id_my: user?.content?.user?.hashed_id ?? null,
                            current_page: window.location.href,
                        },
                    }),
                );
            });
        }
    },
};

export default weboramaComponent;
