/* eslint-disable max-len */
import DateRenderer from '../DateRenderer';

export default class ShowHorizontalRenderer {
    constructor(dataLayerTemplate) {
        this.dataLayerTemplate = dataLayerTemplate;
        this.dateRenderer = new DateRenderer();
    }

    render(recommendation) {
        return `
<article class="swiper-slide tw-relative tw-flex tw-flex-shrink-0 tw-flex-col tw-h-auto tw-w-[250px] md:tw-w-[300px] tw-rounded-large">       
    <a
        href="${recommendation.tracking_url}"
        class="tw-flex-grow tw-flex tw-flex-col tw-bg-white tw hover:tw-shadow-lg focus:tw-shadow-lg
            tw-transition tw-ease-in-out tw-duration-200 hover:tw-transition hover:tw-ease-in-out hover:tw-duration-200
            focus:tw-transition focus:tw-ease-in-out tw-duration-200 tw-relative tw-w-full tw-rounded-large"
        data-layer-event="${this.dataLayerTemplate.event}"
        data-layer-event-cat="${this.dataLayerTemplate.cat}"
        data-layer-event-act="${this.dataLayerTemplate.act}"
        data-layer-event-lab="${this.dataLayerTemplate.lab.replace('{title}', recommendation.title)}"
    >
        <div class="tw-flex-grow tw-px-3 sm:tw-px-6 tw-pt-4 sm:tw-pt-5 tw-pb-6
                tw-border-solid tw-border tw-border-gray-400 tw-rounded-t-large tw-font-heading tw-border-b-0"
        >
            ${recommendation.city ? `
                <div class="tw-flex tw-justify-between tw-items-center tw-mb-4 sm:tw-mb-6">
                    <div class="tw-text-green-700 tw-uppercase tw-font-medium tw-truncate">
                        ${recommendation.city}
                    </div>
                </div>` : ''}

            <h3 class="tw-block tw-text-base tw-leading-27px sm:tw-text-2xl sm:tw-leading-6 tw-text-center sm:tw-text-left">
                ${recommendation.title}
            </h3>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center tw-relative tw-text-center
                tw-text-white tw-bg-primary tw-pt-19px tw-pb-19px sm:py-4 tw-rounded-b-large"
        >
            ${this.dateRenderer.render(recommendation)}
        </div>
    </a>
</article>
`;
    }
}
