import { Controller } from '@hotwired/stimulus';

const blocksByFilter = {
    all: [
        'showsBlock',
        'panoramaBlock',
        'specialitiesSimulatorBlock',
        'rankingsBlock',
        'comparatorBlock',
        'testsBlock',
        'jobCardsBlock',
        'studyCardsBlock',
        'studyAdvisorBlock',
        'futurnessBlock',
        'suggestedContentBlock',
    ],
    formation: ['showsBlock', 'specialitiesSimulatorBlock', 'rankingsBlock', 'comparatorBlock', 'studyAdvisorBlock'],
    schoolCenter: ['showsBlock', 'rankingsBlock', 'studyAdvisorBlock'],
    job: ['testsBlock', 'jobCardsBlock', 'studyCardsBlock', 'futurnessBlock'],
    idk: ['showsBlock', 'panoramaBlock', 'testsBlock', 'futurnessBlock', 'suggestedContentBlock'],
};

export default class extends Controller {
    static targets = ['filter', 'mobileFilter'].concat(blocksByFilter.all);

    static classes = ['active', 'inactive', 'mobileActive', 'mobileInactive'];

    static values = {
        currentFilter: { type: String, default: 'all' },
    };

    setCurrentFilter({ params: { value, scrollEnabled } }) {
        this.currentFilterValue = value;

        this.filterTargets.forEach((filter) => {
            this.toggleFilterClasses(filter, value, this.activeClasses, this.inactiveClasses);
        });

        this.mobileFilterTargets.forEach((filter) => {
            this.toggleFilterClasses(filter, value, this.mobileActiveClasses, this.mobileInactiveClasses);
        });

        const firstBlockToShow = blocksByFilter[value][0] ?? null;
        if (scrollEnabled && firstBlockToShow) {
            setTimeout(() => {
                this[`${firstBlockToShow}Target`]?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }

    currentFilterValueChanged(currentFilter, oldFilter) {
        if (currentFilter === oldFilter) {
            return;
        }

        const blocksToShow = blocksByFilter[currentFilter] ?? [];
        blocksToShow.forEach((block) => {
            this[`${block}Target`].classList.remove('tw-hidden');
        });

        const blocksToHide = blocksByFilter.all.filter((block) => !blocksToShow.includes(block));
        blocksToHide.forEach((block) => {
            this[`${block}Target`].classList.add('tw-hidden');
        });
    }

    toggleFilterClasses(filter, value, activeClasses, inactiveClasses) {
        if (filter.getAttribute('data-sso--orientation-block-filter-value-param') !== value) {
            filter.classList.remove(...activeClasses);
            filter.classList.add(...inactiveClasses);
        } else {
            filter.classList.remove(...inactiveClasses);
            filter.classList.add(...activeClasses);
        }
    }
}
