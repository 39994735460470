import deleteGroup from './deleteGroup';

const sal = {
    init() {
        // Init modal for group registration deletion
        deleteGroup.init();
    },
};

export default sal;
