import dataLayerEvents from '../../dataLayerEvents/dataLayerEvents';
import datePickerComponent from '../../datePickerComponent/datePickerComponent';
import formErrors from './formErrors';
import formFields from './formFields';
import specialitiesAndStream from './specialitiesAndStream';
import ssoBreadcrumb from './ssoBreadcrumb';
import studyArea from './studyArea';

const ssoSecondStep = {
    fieldsStudyLevels: ['study_level', 'study_level_children'],
    fieldsToPrintUnderFirstStep: [
        'study_stream',
        'study_specialities',
        'study_stream_children',
        'study_specialities_children',
    ],
    fieldsToPrintUnderStudyChannelStep: [
        'study_area',
        'study_area_children',
    ],
    emulatedProfileFieldsToServiceFields: [
        'siret',
        'recruiter_company_name',
        'recruiter_firstname',
        'recruiter_lastname',
        'recruiter_street_address',
        'recruiter_city',
        'recruiter_country',
        'function_company',
        'workforce_company',
        'activity_domain_company',
    ],
    ssoFirstStepForm: null,
    ssoSecondStepForm: null,
    additionalData: null,
    buildSecondStepForm(secondStepFormConf) {
        this.ssoFirstStepForm = document.querySelector('#sso-first-step-form');
        this.ssoSecondStepForm = document.querySelector('#sso-second-step-form');
        this.ssoFirstStepForm.classList.add('tw-hidden', 'tw-flex-col', 'tw-w-full', 'tw-mt-12');
        this.ssoFirstStepForm.classList.remove('tw-flex');
        this.ssoSecondStepForm.classList.add('tw-flex', 'tw-flex-col', 'tw-w-full', 'tw-mt-12');
        this.ssoSecondStepForm.classList.remove('tw-hidden');
        this.ssoServiceSlug = document.querySelector('#sso-first-step-form-conf').getAttribute('data-sso-x-service-slug');

        if (typeof secondStepFormConf.content.additionalData !== 'undefined') {
            this.additionalData = secondStepFormConf.content.additionalData;
        }

        let dom = '';
        let count = 1;

        const confFormProfiles = [];
        const confFormServices = [];

        secondStepFormConf.content.fields.forEach((field) => {
            if (typeof field.step === 'undefined') {
                return;
            }

            // Pour certains champs on les émulent en "champs selon le service" afin de les avoir en page de fin
            if (field.step === 'profile' && !this.emulatedProfileFieldsToServiceFields.includes(field.id)) {
                confFormProfiles.push(field);
            } else {
                confFormServices.push(field);
            }
        });

        // Create hidden fields required for step two action
        dom += `<input type="hidden" name="hashed_email" value="${secondStepFormConf.content.hashed_email}" />`;
        dom += `<input type="hidden" name="registration_token" value="${secondStepFormConf.content.registration_token}" />`;

        // Create a div for one field in confFormProfile (except for study stream and specialities)
        let studyStreamFieldConf = [];
        let isParentStudyLevel = false;
        let isParentStudyArea = false;
        confFormProfiles.forEach((field) => {
            if (this.fieldsToPrintUnderFirstStep.includes(field.id)) {
                return;
            }

            if (this.fieldsToPrintUnderStudyChannelStep.includes(field.id)) {
                return;
            }

            if (field.id === 'study_level_children') {
                isParentStudyLevel = true;
            }

            dom += `<div class="tw-hidden tw-flex-col" id="sso-second-step-${count}" data-sso-x-field="${field.id}">`;
            dom += formFields.getDom(field);

            if (this.fieldsStudyLevels.includes(field.id)) {
                confFormProfiles.forEach((fieldToPrintUnderStudyLevelStep) => {
                    // eslint-disable-next-line max-len
                    if (fieldToPrintUnderStudyLevelStep.id === 'study_stream' || fieldToPrintUnderStudyLevelStep.id === 'study_stream_children') {
                        studyStreamFieldConf = fieldToPrintUnderStudyLevelStep;
                    }

                    if (this.fieldsToPrintUnderFirstStep.includes(fieldToPrintUnderStudyLevelStep.id)) {
                        dom += formFields.getDom(fieldToPrintUnderStudyLevelStep);
                    }
                });
            } else if (field.id === 'study_channel_interest') {
                confFormProfiles.forEach((fieldToPrintUnderStudyChannelStep) => {
                    if (this.fieldsToPrintUnderStudyChannelStep.includes(fieldToPrintUnderStudyChannelStep.id)) {
                        if (fieldToPrintUnderStudyChannelStep.id === 'study_area_children') {
                            isParentStudyArea = true;
                        }

                        dom += formFields.getDom(fieldToPrintUnderStudyChannelStep);
                    }
                });
            }

            dom += '</div>';
            count += 1;
        });

        // For fields which depend on the service, we print all the fields in the same div
        dom += `<div class="tw-hidden tw-flex-col" id="sso-second-step-${count}">`;
        let hasPrintOptinMessage = false;
        confFormServices.forEach((field) => {
            if (!hasPrintOptinMessage && field.id.includes('optin')) {
                hasPrintOptinMessage = true;
                // eslint-disable-next-line max-len
                dom += "<p>En validant mon inscription, j'atteste avoir plus de 15 ans ou avoir obtenu l’autorisation de mes parents ou tuteurs légaux :</p>";
            }

            if (field.id === 'optin_call') {
                dom += '<div class="tw-flex tw-bg-red-25 tw-mb-4 sm:tw-px-8 sm:tw-py-6 tw-px-4 tw-py-4">';
            }

            dom += formFields.getDom(field);

            // message must go after new optin call checkbox + optin call has a different display
            if (field.id === 'optin_call') {
                dom += '</div>';
                // eslint-disable-next-line max-len
                dom += "<p class=\"tw-mb-4\">En cochant ces cases, vous pourrez être contactés par sms, téléphone, email et réseaux sociaux. Vous pouvez retirer votre consentement à tout moment en nous contactant à l'adresse <a href=\"mailto:dpo@letudiant.fr\" class=\"tw-text-primary\">dpo@letudiant.fr</a> ou en suivant les liens de désabonnement figurant dans les communications. Pour en savoir plus sur la façon dont nous protégeons vos données, consultez notre <a href=\"/corporate/article/politique-de-protection-des-donnees.html\" class=\"tw-text-primary\">charte de protection des données</a> dans laquelle figurent nos <a href=\"/corporate/article/politique-de-protection-des-donnees.html#5-destinataire-des-donnees\" class=\"tw-text-primary\">partenaires</a>. « l'Etudiant » désigne ci-dessus la société l'Etudiant et ses sociétés affiliées Futurness et StudentRecrut.<p>";
            }
        });
        dom += '</div>';

        dom += formFields.getActionsSecondStep();

        this.ssoSecondStepForm.innerHTML = dom;

        if (datePickerComponent !== false) {
            datePickerComponent.displayDatePicker();
        }

        formFields.handleAutocomplete();
        specialitiesAndStream.handle(this.additionalData, studyStreamFieldConf, isParentStudyLevel);
        studyArea.handle(this.additionalData, isParentStudyArea);

        // Update Breadcrumb to step two
        ssoBreadcrumb.updateBreadcrumb(count + 1, 2);
        this.handleSecondStepForm(count);
    },
    handleSecondStepForm(stepsNumber) {
        let currentStep = 1;
        document.querySelector(`#sso-second-step-${currentStep}`).classList.remove('tw-hidden');
        document.querySelector(`#sso-second-step-${currentStep}`).classList.add('tw-flex');
        document.querySelector('#sso-second-step-back').classList.add('tw-hidden');

        document.querySelector('#sso-second-step-back').addEventListener('click', () => {
            // eslint-disable-next-line max-len
            document.querySelector('#sso-second-step-next').innerHTML = '<span>Suivant</span><i class="fa fa-arrow-right fa-w-14 tw-ml-2"></i>';
            document.querySelector(`#sso-second-step-${currentStep}`).classList.add('tw-hidden');
            document.querySelector(`#sso-second-step-${currentStep}`).classList.remove('tw-flex');
            // eslint-disable-next-line no-plusplus
            currentStep--;
            document.querySelector(`#sso-second-step-${currentStep}`).classList.remove('tw-hidden');
            document.querySelector(`#sso-second-step-${currentStep}`).classList.add('tw-flex');

            if (currentStep === 1) {
                document.querySelector('#sso-second-step-back').classList.add('tw-hidden');
            }

            ssoBreadcrumb.updateBreadcrumb(stepsNumber + 1, currentStep + 1);
            window.scrollTo(0, 0);
        });

        document.querySelector('#sso-second-step-next').addEventListener('click', () => {
            const field = document.querySelector(`#sso-second-step-${currentStep}`);
            if (!field) {
                return;
            }

            const formData = new FormData(document.querySelector('#sso-second-step-form'));
            const fieldName = field.getAttribute('data-sso-x-field');

            if (fieldName) {
                if ((formData.get(fieldName) === '' && formData.get(`${fieldName}[]`) === '')
                    || (formData.get(fieldName) === null && formData.get(`${fieldName}[]`) === null)
                    || (formData.get(fieldName) === '' && formData.get(`${fieldName}[]`) === null)
                    || (formData.get(fieldName) === null && formData.get(`${fieldName}[]`) === '')
                ) {
                    document.querySelector(`#${fieldName}-error`).innerHTML = 'Cette valeur est requise';

                    return;
                }

                if (!specialitiesAndStream.canSubmit(formData)) {
                    return;
                }

                if (!studyArea.canSubmit(formData)) {
                    return;
                }

                const error = document.querySelector(`#${fieldName}-error`);
                if (error) {
                    error.innerHTML = '';
                }
            }

            const nextStepButton = document.querySelector('#sso-second-step-next');
            nextStepButton.setAttribute('data-layer-event-act', `Etape ${currentStep + 1} validée`);

            // For last step, we change the input label
            nextStepButton.innerHTML = '<span>Suivant</span><i class="fa fa-arrow-right fa-w-14 tw-ml-2"></i>';
            if (currentStep + 1 === stepsNumber || currentStep === stepsNumber) {
                nextStepButton.innerHTML = 'Valider';
            }

            if (currentStep === stepsNumber) {
                this.submitSecondStep();

                return;
            }
            nextStepButton.type = 'button';
            this.pushDataLayerEvent();

            document.querySelector(`#sso-second-step-${currentStep}`).classList.add('tw-hidden');
            document.querySelector(`#sso-second-step-${currentStep}`).classList.remove('tw-flex');
            // eslint-disable-next-line no-plusplus
            currentStep++;
            document.querySelector(`#sso-second-step-${currentStep}`).classList.remove('tw-hidden');
            document.querySelector(`#sso-second-step-${currentStep}`).classList.add('tw-flex');

            if (currentStep > 1) {
                document.querySelector('#sso-second-step-back').classList.remove('tw-hidden');
            }

            ssoBreadcrumb.updateBreadcrumb(stepsNumber + 1, currentStep + 1);
            window.scrollTo(0, 0);
        });
    },
    submitSecondStep() {
        const formData = new FormData(document.querySelector('#sso-second-step-form'));

        const request = new XMLHttpRequest();
        request.open('POST', `/compte/${this.ssoServiceSlug}/inscription.html`);
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        request.send(formData);

        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
                const response = JSON.parse(request.responseText);

                if (typeof response.errors !== 'undefined' && response.errors !== null) {
                    formErrors.handleErrors(response.errors.fields);
                } else {
                    this.pushDataLayerEvent();
                    const confirmationPopin = document.querySelector('#register-confirm-modal');

                    if (confirmationPopin !== null) {
                        confirmationPopin.classList.remove('tw-hidden');
                        confirmationPopin.querySelector('[data-modal-close]').addEventListener('click', (e) => {
                            e.preventDefault();
                            window.location.replace(response.referer);
                        });
                    }
                }
            }
        };
    },
    pushDataLayerEvent() {
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push(dataLayerEvents.getEventParameters(document.querySelector('#sso-second-step-next')));
        }
    },
};

export default ssoSecondStep;
