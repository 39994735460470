import axios from 'axios';

const deleteGroup = {
    init() {
        const openModalButtons = document.querySelectorAll('.sal-modal-delete-open');
        const bodyBlackout = document.querySelector('#body-blackout');
        const popupModal = document.querySelector('[data-popup-modal="sal-modal-delete"]');
        const modalClose = document.querySelector('[data-popup-modal="sal-modal-delete"] .close');
        const modalCloseButton = document.querySelector('#sal-modal-delete-cancel');
        const modalConfirmButton = document.querySelector('#sal-modal-delete-confirm');

        if (modalCloseButton) {
            modalCloseButton.addEventListener('click', () => {
                popupModal.classList.remove('is-visible');
                bodyBlackout.classList.remove('is-blacked-out');
                document.querySelector('body').classList.add('tw-relative');
            });
            modalConfirmButton.setAttribute('data-url', '#');
        }

        if (modalClose) {
            modalClose.addEventListener('click', () => {
                popupModal.classList.remove('is-visible');
                bodyBlackout.classList.remove('is-blacked-out');
                document.querySelector('body').classList.add('tw-relative');
            });
            modalConfirmButton.setAttribute('data-url', '#');
        }

        if (bodyBlackout) {
            bodyBlackout.addEventListener('click', () => {
                popupModal.classList.remove('is-visible');
                bodyBlackout.classList.remove('is-blacked-out');
                document.querySelector('body').classList.remove('tw-relative');
            });
        }

        if (openModalButtons) {
            openModalButtons.forEach((openModalButton) => {
                openModalButton.addEventListener('click', () => {
                    const url = openModalButton.getAttribute('data-url');
                    modalConfirmButton.setAttribute('data-url', url);

                    popupModal.classList.add('is-visible');
                    bodyBlackout.classList.add('is-blacked-out');
                    document.querySelector('body').classList.add('tw-relative');
                });
            });
        }

        if (modalConfirmButton) {
            modalConfirmButton.addEventListener('click', () => {
                const url = modalConfirmButton.getAttribute('data-url');

                axios.delete(`${BASE_URL}${url}`)
                    .then(() => {
                        window.location.reload();
                    })
                    .catch(() => {
                        popupModal.classList.remove('is-visible');
                        bodyBlackout.classList.remove('is-blacked-out');
                        document.querySelector('body').classList.add('tw-relative');
                    });
            });
        }
    },
};

export default deleteGroup;
