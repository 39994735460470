import { Controller } from '@hotwired/stimulus';

const counterElementAttribute = '[data-gan--modal-filters-counter="counter"]';
const counterSelectors = [
    'input[data-gan--modal-filters-counter="element"][type="hidden"][value]:not([value=""])',
    'input[data-gan--modal-filters-counter="element"][type="checkbox"]:checked',
    'option[data-gan--modal-filters-counter="element"][selected="selected"]',
].join(', ');

export default class extends Controller {
    static targets = ['form'];

    connect() {
        this.update();
    }

    update() {
        this.formTargets.forEach((formTarget) => {
            const counter = formTarget.querySelectorAll(counterSelectors).length;
            const counterElement = formTarget.querySelector(counterElementAttribute);

            counterElement.innerText = counter;

            counterElement.classList.toggle('tw-hidden', !counter);
        });
    }

    reset(e) {
        if (e.detail.form.contains(this.element)) {
            this.update();
        }
    }
}
